import * as React from "react";

import { AppBar, Box, Collapse, Container, Drawer, Hidden, IconButton, Link, List, ListItem, ListItemText, Toolbar, Typography } from "@mui/material";
import { LinkedinIcon, PhoneIcon } from "../CustomIcons";
import { NavLink, useLocation } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PerfectScrollbar from "react-perfect-scrollbar";
import { faBars } from "@fortawesome/free-solid-svg-icons"
import logo from "./../../Assets/images/logo_carmen.png";
import logoBlack from "./../../Assets/images/carmen_modal.png";
import useStyles from "./styles";

export default function Header() {
  const [headerScroll, setHeaderScroll] = React.useState(false)
  const classes = useStyles();
  const location = useLocation();
  const [openMenu, setOpenMenu] = React.useState(false);

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const listenScrollEvent = (event: any) => {
    if (window.scrollY <= 10) {
      return setHeaderScroll(false);
    } else if (window.scrollY > 10) {
      return setHeaderScroll(true);
    }
  };
  React.useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  const handleMenuOpen = () => {
    setOpenMenu(true);
  }

  const handleMenuClose = () => {
    setOpenMenu(false);
  }


  const currPath = location.pathname;
  const pathPrefix = "/";

  return (
    <>
      <AppBar position="fixed" color="transparent">
        <Box sx={{ bgcolor: "primary.main" }} >
          <Box sx={{ bgcolor: "primary.main" }} className={classes.headerTop}>
            <Container>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", alignItems: "center", color: "text.secondary" }} className={classes.textAddress}>
                  <Typography sx={{ display: "inline" }} variant="h5">
                    Alameda Mario Vargas Llosa 338 (Ex Av. Parra), Arequipa - Perú <Link href="tel:054215542" color="inherit" underline="none"><PhoneIcon /> 054-215 542</Link>
                  </Typography>
                </Box>
                <Hidden smDown>
                  <Box pl={4}>
                    <Link href="https://pe.linkedin.com/company/carmen-grupo-inca?trk=organization-update_share-update_actor-text" target="_blank" className={classes.iconLinkedin} sx={{ display: "flex" }}>
                      <LinkedinIcon />
                    </Link>
                  </Box>
                </Hidden>
              </Box>
            </Container>
          </Box>
          <Hidden smDown>
            <Box sx={{ bgcolor: "text.secondary" }} className={`${classes.headerInfo} ${headerScroll === true ? "hiddenHeader" : ""}`}>
              <Container>
                <Box sx={{ textAlign: "center", color: "primary.main" }}>
                  <Typography variant="h5">¿Quieres trabajar con nosotros?, <Link href="/trabaja-con-nosotros" sx={{ color: "secondary.main" }} underline="none">haz click aquí</Link></Typography>
                </Box>
              </Container>
            </Box>
          </Hidden>
        </Box>
        <Box sx={{ bgcolor: headerScroll ? "primary.contrastText" : "transparent", boxShadow: headerScroll ? "0 1px 3px rgba(40,40,40,0.12), 0 1px 2px rgba(40,40,40,0.24)" : "none" }} className={classes.contHeader}>
          <Container>
            <Toolbar disableGutters={true} sx={{ display: "flex", justifyContent: "space-between" }} className={classes.header}>
              <NavLink to="/" className={classes.logo}>
                {
                  (currPath === pathPrefix) ? (headerScroll ? <img src={logoBlack} alt="logo"/> : <img src={logo} alt="logo"/>) : <img src={logoBlack} alt="logo"/>
                }

              </NavLink>
              <Hidden mdDown>
                <Box sx={{ color: (currPath === pathPrefix) ? (headerScroll ? "primary" : "primary.contrastText") : "primary" }}>
                  <ul className={`${classes.navMenu} ${(currPath === pathPrefix) ? (headerScroll ? "changeHover" : "") : "changeHover"}`}>
                    <li>
                      <NavLink to="/nosotros" activeClassName="active-link">Nosotros</NavLink>
                    </li>
                    <li className={classes.hasSubMenu}>
                      <div>Servicios</div>
                      <ul className={classes.subMenu}>
                        <li>
                          <NavLink to="/servicios/construccion" activeClassName="active-link">Construcción</NavLink>
                        </li>
                        <li>
                          <NavLink to="/servicios/ingenieria" activeClassName="active-link">Ingeniería</NavLink>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink to="/portafolio" activeClassName="active-link">Portafolio</NavLink>
                    </li>
                    <li>
                      <NavLink to="/sig" activeClassName="active-link">Sig</NavLink>
                    </li>
                    <li>
                      <NavLink to="/trabaja-con-nosotros" activeClassName="active-link">Trabaja con Nosotros</NavLink>
                    </li>
                    <li>
                      <NavLink to="/contacto" className="linkContacto" activeClassName="active-link">Contacto</NavLink>
                    </li>
                    <li>
                      <Link href="https://www.dropbox.com/sh/fsiznqarqrd3dq9/AAAyi3xcpXLMHP8A74nL3eAka?dl=0" target="_blank" className="linkContacto">
                        <FileDownloadIcon style={{fontSize: 'medium'}}/>
                        Descargar Portafolio
                      </Link>
                    </li>
                  </ul>
                </Box>
              </Hidden>
              <Hidden mdUp>
                <IconButton aria-label="delete" size="large" onClick={openMenu ? handleMenuClose : handleMenuOpen} sx={{ color: "secondary.main", marginRight: "-12px" }}>
                  <FontAwesomeIcon icon={faBars} size="1x" color="inherit" />
                </IconButton>
              </Hidden>
            </Toolbar>
          </Container>
        </Box>
      </AppBar>
      <Drawer
        open={openMenu}
        anchor="right"
        elevation={0}
        onClose={handleMenuClose}
        classes={{ root: classes.drawer }}
        PaperProps={{ classes: { root: classes.drawerStyle } }}
      >
        <PerfectScrollbar>
          <Box className={classes.drawerWrapper}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} pb={2}>
              <NavLink to="/" className={classes.logo}>
                <img src={logoBlack} alt="logo"/>
              </NavLink>
              <IconButton
                color="inherit"
                onClick={handleMenuClose}
                className={classes.closeMenuIcon}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <List
              sx={{ width: "100%" }}
              component="ul"
              aria-labelledby="nested-list-subheader"
              className={classes.menuResponsive}
            >
              <ListItem button component="li">
                <NavLink to="/nosotros" activeClassName="active-link" onClick={handleMenuClose} onKeyDown={handleMenuClose}>Nosotros</NavLink>
              </ListItem>

              <ListItem onClick={handleClick} component="li">
                <ListItemText primary="Servicios" />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="ul" disablePadding>
                  <ListItem component="li" sx={{ p: ".3rem 1rem !important" }}>
                    <NavLink to="/servicios/construccion" activeClassName="active-link" onClick={handleMenuClose} onKeyDown={handleMenuClose}>Construcción</NavLink>
                  </ListItem>
                  <ListItem component="li" sx={{ p: ".3rem 1rem !important" }}>
                    <NavLink to="/servicios/ingenieria" activeClassName="active-link" onClick={handleMenuClose} onKeyDown={handleMenuClose}>Ingeniería</NavLink>
                  </ListItem>
                </List>
              </Collapse>
              <ListItem component="li">
                <NavLink to="/portafolio" activeClassName="active-link" onClick={handleMenuClose} onKeyDown={handleMenuClose}>Portafolio</NavLink>
              </ListItem>
              <ListItem component="li">
                <NavLink to="/sig" activeClassName="active-link" onClick={handleMenuClose} onKeyDown={handleMenuClose}>Sig</NavLink>
              </ListItem>
              <ListItem component="li">
                <NavLink to="/trabaja-con-nosotros" activeClassName="active-link" onClick={handleMenuClose} onKeyDown={handleMenuClose}>Trabaja con Nosotros</NavLink>
              </ListItem>
              <ListItem component="li">
                <NavLink to="/contacto" className="linkContacto" activeClassName="active-link" onClick={handleMenuClose} onKeyDown={handleMenuClose}>Contacto</NavLink>
              </ListItem>
              <ListItem component="li">
                <Link href="https://www.dropbox.com/sh/fsiznqarqrd3dq9/AAAyi3xcpXLMHP8A74nL3eAka?dl=0" target="_blank" className="linkContacto" onClick={handleMenuClose} onKeyDown={handleMenuClose}>
                  <FileDownloadIcon style={{fontSize: 'medium'}}/>
                  Descargar Portafolio
                </Link>
              </ListItem>
            </List>
          </Box>
        </PerfectScrollbar>
      </Drawer>
    </>
  );
}
