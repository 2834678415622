import * as React from "react";

import { Box, Container, Grid, Hidden, Link, Typography } from "@mui/material";
import { Img } from "react-image";

import { FacebookIcon, LinkedInIconLight } from "../CustomIcons";
import logoCarmen from "./../../Assets/images/logo_carmen.png";
import logoWhiteGrupoInca from "./../../Assets/images/logo_grupo_inca_white.png";
import useStyles from "./styles";

export default function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Box className={classes.contFooter}>
        <Container>
          <Grid container justifyContent="space-between">
            <Grid item sm={5} xs={12}>
              <Box className={classes.contTextFooter}>
                <Img src={logoCarmen} className={classes.logoCarmen} />
                <Typography sx={{ fontSize: "12px", lineHeight: "1.4", color: "rgba(255 255 255 / .7)" }}>
                  Un ambiente para el desarrollo de los profesionales que son LA MATERIA PRIMA de CARMEN.
                </Typography>
                <Box className={classes.textSomosParte}>
                  <Typography sx={{ color: "primary.contrastText", fontSize: "13px" }}>
                    Somos parte de:
                  </Typography>
                  <Link href="https://grupoinca.com/es/" target="_blank" sx={{ display: "block" }}>
                    <Img src={logoWhiteGrupoInca} className={classes.logoGrupoInca} />
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Grid container spacing={3} justifyContent="space-between">
                <Hidden smDown>
                  <Grid item xs={6}>
                    <Box>
                      <Typography variant="caption" display="block" component="div" className={classes.titleItems} sx={{ color: "primary.contrastText" }}>
                        Mapa del Sitio
                      </Typography>
                      <ul className={classes.itemsLinkFooter}>
                        <li><Link href="/nosotros" color="primary.contrastText" >Nosotros</Link></li>
                        <li><Link href="/servicios/construccion" color="primary.contrastText" >Servicios</Link></li>
                        <li><Link href="/portafolio" color="primary.contrastText" >Portafolio</Link></li>
                        <li><Link href="/sig" color="primary.contrastText" >Sig</Link></li>
                        <li><Link href="/trabaja-con-nosotros" color="primary.contrastText" >Bolsa de Empleo</Link></li>
                        <li><Link href="/contacto" color="primary.contrastText" >Contacto</Link></li>
                      </ul>
                    </Box>
                  </Grid>
                </Hidden>
                <Grid item sm={6} xs={12}>
                  <Box>
                    <Hidden smDown>
                      <Typography variant="caption" display="block" component="div" className={classes.titleItems} sx={{ color: "primary.contrastText" }}>
                        Síguenos
                      </Typography>
                    </Hidden>
                    <ul className={classes.itemsLinkSocialFooter}>
                      <li>
                        <Link href="https://www.facebook.com/Carmen-Grupo-Inca-2242335112450079" target="_blank" sx={{ color: "primary.contrastText" }}>
                          <FacebookIcon />
                        </Link>
                      </li>
                      <li>
                        <Link href="https://pe.linkedin.com/company/carmen-grupo-inca?trk=organization-update_share-update_actor-text" target="_blank" sx={{ color: "primary.contrastText" }}>
                          <LinkedInIconLight />
                        </Link>
                      </li>
                    </ul>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className={classes.contText}>
        <Typography sx={{ color: "primary.contrastText", textAlign: "center", fontSize: "12px", lineHeight: "1.4" }}>Carmen Inmuebles S.A. 2021 - Todos los derechos reservados - Desarrollado por <Link href="https://inka-labs.com/" underline="hover" target="_blank" color="inherit">INKALABS</Link></Typography>
      </Box>
    </footer>
  );
}
