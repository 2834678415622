import * as React from "react";

import { Box, Button, ButtonProps, Dialog, DialogContent, Grid, IconButton, Link } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import CloseIcon from "@mui/icons-material/Close";
import { Img } from "react-image";
import logoCarmen from "./../../Assets/images/carmen_modal.png";
import logoNave from "./../../Assets/images/nave.jpg";
import logoSingular from "./../../Assets/images/singular.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import useStyles from "./styles";

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  border: ".1rem solid #913617",
  minWidth: "auto",
  fontFamily: ["'OptimalLTStdBold'", "'sans-serif'"].join(","),
  lineHeight: "1.2",
  padding: "7px 10px",
  textTransform: "initial",
  fontSize: 16,
  letterSpacing: "0",
  width: "100%",
  textAlign: "center",
  "&:hover": {
    border: ".1rem solid #913617",
    background: "#913617",
    color: "#ffffff",
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    maxWidth: 870,
    borderRadius: 0,
    overflow: "initial",
  },
  "& .MuiDialogContent-root": {
    padding: "5rem 4.4rem",
    [theme.breakpoints.down("sm")]: {
      padding: "3.8rem 2.4rem",
    },
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export default function WelcomeModal() {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
    >
      <DialogContent>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: -19,
            top: -19,
            color: (theme) => theme.palette.primary.contrastText,
            background: (theme) => theme.palette.secondary.main,
            borderRadius: 0,
            padding: ".3rem",
          }}
          className={classes.btnClose}
        >
          <CloseIcon />
        </IconButton>
        {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography component="div" sx={{ fontFamily: ["'OptimalLTStdDemiBold'", "'sans-serif'"].join(","), fontSize: 22, letterSpacing: 0 }} className={classes.titleWelcome}>
            Te damos la bienvenida
          </Typography>
        </Box> */}
        <Grid container spacing={matches ? 6 : 4} justifyContent="center">
          <Grid item md={4} sm={6} xs={12}>
            <Box className={classes.contLogoProyect}>
              <Box className={classes.contImage}>
                <Img src={logoSingular} className={classes.logoProjectSigular} />
              </Box>
              <Link href="" target="_blank" underline="none">
                <ColorButton variant="outlined" size="small" color="secondary">Servicios Inmobiliarios</ColorButton>
              </Link>
            </Box>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Box className={classes.contLogoProyect}>
              <Box className={classes.contImage}>
                <Img src={logoCarmen} className={classes.logoProject} />
              </Box>
              <ColorButton variant="outlined" size="small" color="secondary" onClick={handleClose}>Servicios de Ingeniería y Construcción</ColorButton>
            </Box>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Box className={classes.contLogoProyect}>
              <Box className={classes.contImage}>
                <Img src={logoNave} className={classes.logoProject} />
              </Box>
              <Link href="https://carmen-inmuebles.com.pe/nave.com.pe/" target="_blank" underline="none">
                <ColorButton variant="outlined" size="small" color="secondary">Servicios de Almacenamiento</ColorButton>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </BootstrapDialog>
  );
}
