import * as React from "react";

import { Box, Container } from "@mui/material";

import { Img } from "react-image";
import logoBIM from "./../../Assets/images/bim.png";
import logoBureau from "./../../Assets/images/bureau.png";
import logoPM from "./../../Assets/images/pm.png";
import logornp from "./../../Assets/images/rnp.png";
import useStyles from "./styles";

interface IRecognitionsProps {
  bg: string;
}
export default function Recognitions({ bg }: IRecognitionsProps) {
  const classes = useStyles();

  return (
    <Box sx={{ bgcolor: bg }} className={classes.recognitions}>
      <Container>
        <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
          <Box className={classes.itemLogo}>
            <Img src={logoPM} />
          </Box>
          <Box className={classes.itemLogo}>
            <Img src={logoBIM} />
          </Box>
          {/* <Box className={classes.itemLogo}>
            <Img src={logoPremio} />
          </Box> */}
          <Box className={classes.itemLogo}>
            <Img src={logornp} />
          </Box>
          <Box className={classes.itemLogo}>
            <Img src={logoBureau} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
