import React from "react";

import { SvgIcon } from "@mui/material";

export default function LinkedInIconLight(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 29.5 29.4">
      <path d="M0.5,9.8h6.1v19.7H0.5V9.8z M3.5,0c2,0,3.5,1.6,3.5,3.5c0,2-1.6,3.5-3.5,3.5C1.6,7.1,0,5.5,0,3.5
		C0,1.6,1.6,0,3.5,0"/>
      <path d="M10.4,9.8h5.9v2.7h0.1c0.8-1.5,2.8-3.2,5.8-3.2c6.2,0,7.3,4.1,7.3,9.4v10.8h-6.1v-9.6c0-2.3,0-5.2-3.2-5.2
		c-3.2,0-3.7,2.5-3.7,5v9.7h-6.1V9.8z"/>
    </SvgIcon>
  )
}
