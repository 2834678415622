import { createStyles, makeStyles } from "@mui/styles";

import { Theme } from "@mui/material/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    mainContent: {
      display: "grid",
      gap: "0.5rem",
      gridTemplateColumns: "repeat(3, 1fr)",


      width: "min(95%, 70rem)",
      marginInline: "auto"
    },
    contSectionPortafolio: {
      margin: "4.5rem 0",
      [theme.breakpoints.down("sm")]: {
        margin: "4.5rem 0",
      },
    },
    contItemCarousel: {
      overflow: "hidden",
      textDecoration: "none",
      
      [theme.breakpoints.down("md")]: {
        padding: "0",
      },
      "&:first-child": {
        gridColumn: "span 2"
      },
      "&:last-child": {
        gridRowStart: "3",
        gridColumn: "span 3"
      },
    },
    itemCarousel: {
      position: "relative",
      padding: "2rem 8%",
      [theme.breakpoints.down("md")]: {
        "& h2": {
          fontSize: 58,
        },
        "& h6": {
          fontSize: 22,
        },
      },
      "&::before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        background: "rgba(145 54 23 / .94)",
        zIndex: 0,
      },
      "&::after": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        // backgroundImage: "linear-gradient(to top, rgba(0, 0, 0, 0.45) 0%, transparent 60%)",
        zIndex: 1,
        transition: "background-image .4s",
      },
    },
    titleDescription: {
      paddinBottom: 0,
    },
    description: {
      height: 0,
      marginBottom: "-100%",
      opacity: 0,
      transition: "margin-bottom .3s cubic-bezier(0.25, 0.46, 0.45, 0.94)",
      "& p": {
        marginBottom: ".4rem",
        "&:last-of-type": {
          marginBottom: 0,
        },
      },
    },


    gridPortafolio: {
      margin: "4.5rem 0",
    },
    contCardPortafolio: {
      height: "21rem",
      overflow: "hidden",
      textDecoration: "none",
    },
    cardPortafolio: {
      position: "relative",
      padding: "2.4rem 8%",
      cursor: "pointer",
      "&::after": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        zIndex: 1,
        transition: "background-image .4s",
        // background: "linear-gradient(0deg, rgba(0,0,0,1) 24%, rgba(15,83,84,0) 69%)",
      },
      "&:hover":{
        "& $descriptionCard": {
          flexDirection: "column",
          maxHeight: "8rem",
          opacity: 1,
          transition: "max-height .4s ease-in, opacity .4s ease",
        },
        "& $titleCard": {
          flexDirection: "column",
          maxHeight: "8rem",
          opacity: 1,
          transition: "max-height .4s ease-in, opacity .4s ease",
        },
        "& $boxDesc": {
          opacity: "76%",
        },
      },
    },
    titleCard: {
      paddingBottom: 0,
      marginBottom: ".2rem",
      opacity: 0,
      transition: "max-height .3s ease-out, opacity .5s ease",
      flexDirection: "column",

    },
    descriptionCard: {
      width: "100%",
      maxHeight: 0,
      opacity: 0,
      transition: "max-height .3s ease-out, opacity .5s ease",
      flexDirection: "column",
      "& p": {
        marginBottom: ".4rem",
        "&:last-of-type": {
          marginBottom: 0,
          fontSize: "3rem",
          fontWeight: "bolder"
        },
      },
      "& button": {
        marginTop: "1rem",
      },
    },
    boxDesc: {
      backgroundColor: "black",
      padding: "2rem",
      borderRadius: "10px",
      opacity: "0"
    }
  })
);