import * as React from "react";

import { Box, Container, Grid, Tab, Tabs, Typography } from "@mui/material";

import useStyles from "./styles";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Building() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box className={classes.building}>
      <Container>
        <Box sx={{ width: "100%" }}>
          <Box>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered className={classes.contTitleProject} textColor="secondary">
              <Tab label={<Box className={classes.letter}>C</Box>} {...a11yProps(0)} sx={{ color: "secondary.main", padding: 0, fontWeight: 500, minHeight: 104 }} className={classes.titleProject} disableRipple={true} />
              <Tab label={<Box className={classes.letter}>AR</Box>} {...a11yProps(1)} sx={{ color: "secondary.main", padding: 0, fontWeight: 500, minHeight: 104 }} className={classes.titleProject} disableRipple={true} />
              <Tab label={<Box className={classes.letter}>M</Box>} {...a11yProps(2)} sx={{ color: "secondary.main", padding: 0, fontWeight: 500, minHeight: 104 }} className={classes.titleProject} disableRipple={true} />
              <Tab label={<Box className={classes.letter}>EN</Box>} {...a11yProps(3)} sx={{ color: "secondary.main", padding: 0, fontWeight: 500, minHeight: 104 }} className={classes.titleProject} disableRipple={true} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Box className={classes.textBuilding}>
              <Grid container justifyContent="center">
                <Grid item lg={6} sm={6} xs={12}>
                  <Typography variant="h4" component="h4" sx={{ color: "secondary.main", textAlign: "center" }}>
                    CONSTRUCTION
                  </Typography>
                  <Typography sx={{ textAlign: "center" }}>
                    Desarrollamos proyectos de construcción inmobiliarios, obras industriales, retail, edificaciones comerciales, almacenes.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Box className={classes.textBuilding}>
              <Grid container justifyContent="center">
                <Grid item lg={6} sm={6} xs={12}>
                  <Typography variant="h4" component="h4" sx={{ color: "secondary.main", textAlign: "center" }}>
                    ARCHITECTURE
                  </Typography>
                  <Typography sx={{ textAlign: "center" }}>
                    Diseñamos espacios ideales para el desarrollo de las actividades de nuestros clientes.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Box className={classes.textBuilding}>
              <Grid container justifyContent="center">
                <Grid item lg={6} sm={6} xs={12}>
                  <Typography variant="h4" component="h4" sx={{ color: "secondary.main", textAlign: "center" }}>
                    MANAGEMENT
                  </Typography>
                  <Typography sx={{ textAlign: "center" }}>
                    Gestionamos proyectos asegurando altos estándares de calidad, seguridad y cuidado del medio ambiente aplicando la filosofía LEAN Construction, las buenas prácticas del PMI y VDC-BIM.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Box className={classes.textBuilding}>
              <Grid container justifyContent="center">
                <Grid item lg={6} sm={6} xs={12}>
                  <Typography variant="h4" component="h4" sx={{ color: "secondary.main", textAlign: "center" }}>
                    ENGINEERING
                  </Typography>
                  <Typography sx={{ textAlign: "center" }}>
                    Desarrollamos proyectos de ingeniería utilizando la tecnología más avanzada en las especialidades de: ingeniería estructural, instalaciones de gas, sanitarias, eléctricas, lucha contra incendio, aire acondicionado, especialidades metal mecánicas.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
        </Box>
      </Container>
    </Box>
  );
}
