import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import * as React from "react";

import { Box, Container, Grid, Typography } from "@mui/material";

import Slider from "react-slick";
import useStyles from "./styles";

export default function MissionVision() {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 2500,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      <Box className={classes.missionVission}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item lg={8} sm={10} xs={12}>
            <Slider {...settings} className={classes.carouselMissionVision}>
                <Box className={classes.wrapperMissionVission}>
                  <Typography variant="h4" component="h4" sx={{ color: "secondary.main", textAlign: "center" }}>
                    VISIÓN
                  </Typography>
                  <Typography sx={{ textAlign: "center" }}>
                    Para el año 2025, CARMEN Inmuebles S.A. debe ser la primera opción de elección de nuestros clientes,
                    dentro de la región sur del Perú, en construcción e infraestructura de proyectos, así como ser
                    reconocidos en el mercado por nuestra fiabilidad de productos inmobiliarios innovadores y servicios de
                    almacenamiento logístico de alto estándar.
                  </Typography>
                </Box>
                <Box className={classes.wrapperMissionVission}>
                  <Typography variant="h4" component="h4" sx={{ color: "secondary.main", textAlign: "center" }}>
                    MISIÓN
                  </Typography>
                  <Typography variant="body1" component="div" sx={{ textAlign: "center" }}>
                    Somos una empresa del Grupo Inca especializada en brindar soluciones confiables de construcción e
                    infraestructura, servicios inmobiliarios y almacenamiento logístico.
                    Nuestros servicios se desarrollan con un enfoque de mejora continua y generación de valor a todos
                    los grupos de interés; en un ámbito de formalidad cumpliendo con altos estándares de calidad,
                    seguridad y cuidado del medio ambiente.
                  </Typography>
                </Box>
              </Slider>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
