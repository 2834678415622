import { Box, Container, Link, Typography } from "@mui/material";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { faMapMarkerAlt, faSpinner } from "@fortawesome/free-solid-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import markerCarmen from "./../../Assets/images/marker.png";
import useStyles from "./styles";

const center = {
  lat: -16.41362213359764,
  lng: -71.5461594,
};

const containerStyle = {
  width: "100%",
  height: "100%",
};


export default function Contact() {
  const classes = useStyles();
  return (
    <Box className={classes.sectionContact}>
      <Container>
        <Box>
          <Box sx={{ bgcolor: "secondary.light" }} className={classes.boxContact}>
            <Box mb={3} sx={{ display: "flex", alignItems: "center", color: "secondary.main" }}>
              <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" />
              <Box ml={1.5}>
                <Typography>DIRECCIÓN: Av. Parra 338, Arequipa</Typography>
                <Typography>TELÉFONO: <Link href="tel:054215542" underline="none">(054) 215542</Link></Typography>
              </Box>
            </Box>
            <Box mb={4}>
              <Typography sx={{ color: "secondary.main" }}gutterBottom>INGENIERIA:</Typography>
              <Typography>Correo: <Link href="mailto:jdiaz@carmen.pe" underline="none">jdiaz@carmen.pe</Link></Typography>
              <Typography>Celular: <Link href="tel:949 737 862" underline="none">949 737 862</Link></Typography>
            </Box>
            <Box>
              <Typography sx={{ color: "secondary.main" }} gutterBottom>CONSTRUCCIÓN:</Typography>
              <Typography>Correo: <Link href="mailto:jdiaz@carmen.pe" underline="none">jdiaz@carmen.pe</Link></Typography>
              <Typography>Celular: <Link href="tel:949 737 862" underline="none">949 737 862</Link></Typography>
            </Box>
          </Box>
        </Box>

      </Container>
      <Box className={classes.contMap}>
        <LoadScript
          googleMapsApiKey="AIzaSyCtkV9sSI34uz3NZURQUnwzCrK5C2wawxQ"
          language="en"
          region="EN"
          loadingElement={<Box className={classes.loaderMap}><FontAwesomeIcon icon={faSpinner} color="white" spin size="2x" /></Box>}
        >
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={18}

          >
            <Marker
              icon={markerCarmen}
              position={center}
            />
          </GoogleMap>
        </LoadScript>
      </Box>
    </Box>
  );
}
