import { createStyles, makeStyles } from "@mui/styles";

import { Theme } from "@mui/material/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    sectionContact: {
      paddingTop: "calc(3.8rem + 3rem + 64px)",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "calc(3.8rem + 64px)",
      },
    },
    boxContact: {
      padding: "4.2rem 6%",
      borderRadius: 45,
      margin: ".5rem 0 3rem",
    },
    contMap: {
      height: "25rem",
    },
    loaderMap: {
      position: "relative",
      width: "100%",
      height: "100%",
      background: theme.palette.primary.main,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);