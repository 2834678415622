
import * as React from "react";

import { Box, Container, Grid, Link, Typography } from "@mui/material";
import { Img } from "react-image";

import logoGrupoInca from "./../../Assets/images/logo_grupo_inca.png";
import useStyles from "./styles";

export default function Introduction() {
  const classes = useStyles();

  return (
    <Box sx={{ bgcolor: "primary.light" }} className={classes.introduction}>
      <Container>
        <Grid container spacing={4} alignItems="center">
          <Grid item md={6} xs={12}>
            <Box sx={{ display: "flex", alignItems: "center" }} className={classes.textSomosIntro}>
              <Typography sx={{ letterSpacing: "normal"}}>
                Somos parte de:
              </Typography>
              <Link href="https://grupoinca.com/es/" target="_blank" sx={{ display: "block" }}>
                <Img src={logoGrupoInca} className={classes.logo} />
              </Link>
            </Box>
          </Grid>
          <Grid item md={6} xs={12} className={classes.textIntroduction}>
            <Typography variant="body1">
              Somos una empresa de ingeniería y construcción que forma parte del Grupo Inca y se nutre de los valores y la filosofía de
              este conglomerado empresarial que ha transitado con éxito por el rubro textil, agroindustrial, turismo, hostelería, financiero y
              construcción.

              Hablamos entonces de 60 años de evolución permanente, de respeto a la naturaleza y de responsabilidad social, que son la base de CARMEN.
            </Typography>
            <Typography variant="body1">
              Así, a través de la innovación permanente nos enorgullecemos de brindar el mejor servicio en diseño de arquitectura e ingenieria, gerenciamiento de proyectos y construcción.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
