import { Route, Switch } from "react-router-dom";

import BriefcasePage from "Views/BriefcasePage";
import ConstructionPage from "Views/ConstructionPage";
import ContactPage from "Views/ContactPage";
import EngineeringPage from "Views/EngineeringPage";
import HomePage from "Views/HomePage";
import InternalPortafolioPage from "Views/InternalPortafolioPage";
import React from "react";
import SigPage from "Views/SigPage";
import UsPage from "Views/UsPage"
import WorkWithUsPage from "Views/WorkWithUsPage";

export const baseUrl = "/";
export const UsUrl = `${baseUrl}nosotros`;
export const ConstruccionUrl = `${baseUrl}servicios/construccion`;
export const IngenieriaUrl = `${baseUrl}servicios/ingenieria`;
export const PortafolioUrl = `${baseUrl}portafolio`;
export const InternalPortafolioUrl = `${baseUrl}portafolio/:slug`;
export const ContactoUrl=`${baseUrl}contacto`;
export const SigUrl=`${baseUrl}sig`;
export const WorkWithUsUrl=`${baseUrl}trabaja-con-nosotros`;

export const PortafolioRoutes: React.FC = () => (
  <Switch>
    <Route exact path={PortafolioUrl} component={BriefcasePage} />
    <Route path={InternalPortafolioUrl} component={InternalPortafolioPage} />
    <Route component={BriefcasePage} />
  </Switch>
);

export const Routes: React.FC = () => (
  <Switch>
    <Route exact path={baseUrl} component={HomePage} />
    <Route path={UsUrl} component={UsPage} />
    <Route path={ConstruccionUrl} component={ConstructionPage} />
    <Route path={IngenieriaUrl} component={EngineeringPage} />
    <Route path={PortafolioUrl} component={PortafolioRoutes}></Route>
    <Route path={ContactoUrl} component={ContactPage}></Route>
    <Route path={SigUrl} component={SigPage}></Route>
    <Route path={WorkWithUsUrl} component={WorkWithUsPage}></Route>
  </Switch>
);

export default Routes;
