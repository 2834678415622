import React from "react";

import { SvgIcon } from "@mui/material";

export default function FacebookIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 18.3 29.4">
      <path d="M17.1,16.8l0.9-5.4h-5.9V7.9c0-1.5,0.8-2.9,3.5-2.9h2.7V0.4c0,0-2.4-0.4-4.8-0.4C8.6,0,5.4,2.6,5.4,7.3v4.1H0
		v5.4h5.4v12.7h6.7V16.8H17.1z"/>
    </SvgIcon>
  )
}
