import { Box, Button, Container, Grid, Typography } from "@mui/material";

import { useState } from "react";
import useStyles from "./styles";

export default function WorkWithUs() {
  const classes = useStyles();
  const [formularioEnviado] = useState(false);
  const loadImage = (imageName: string) => (require(`./../../Assets/images/${imageName}`).default);

  return (
    <Box className={classes.sectionContact}>
      <Container>
        <Box>
          <Box sx={{ bgcolor: "secondary.light" }} className={classes.boxContact}>
            <Grid container alignItems="center">
              <Grid item xs={12} md={6} sm={4} style={{padding: '2rem 2rem'}}>
                <Typography variant="subtitle1" component="div" sx={{ color: "primary.main", textAlign: "left", marginBottom: "1.8rem" }} className={classes.textCapion}>
                  {<div>Trabaja con<br />Nosotros</div>}
                </Typography>
                <Typography variant="body1" sx={{ textAlign: "justify" }}>
                  Déjanos tus datos y adjunta tu CV (máximo 2MB), de encajar con alguno de nuestros perfiles nos pondremos en contacto contigo.<br/><br/>
                  Del mismo modo, de tener una empresa o emprendimiento relacionado, déjanos sus datos y adjunta su carta de presentación (máximo 2MB).            
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} sm={4} >
                <form className={classes.formulario} method="POST" action="https://formsubmit.co/reclutamiento@carmen.pe" encType="multipart/form-data">
                  <div>
                    <input 
                      className={classes.customInputs}
                      type="text" 
                      id="nombre" 
                      name="nombre" 
                      placeholder="Nombre" 
                    />
                  </div>
                  <div>
                    <input 
                      className={classes.customInputs}
                      type="text" 
                      id="asunto" 
                      name="asunto" 
                      placeholder="Asunto" 
                    />
                  </div>
                  <div>
                    <input 
                      className={classes.customInputs}
                      type="text" 
                      id="correo" 
                      name="correo" 
                      placeholder="Correo Electrónico" 
                    />
                  </div>
                  <div>
                    <input 
                      className={classes.customInputs}
                      type="text" 
                      id="telefono" 
                      name="telefono" 
                      placeholder="Teléfono" 
                    />
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      component="label"
                      style={{backgroundColor: '#913616', color: 'white', width: '300px', textTransform: 'capitalize', fontSize: '12px'}}
                    >
                      Adjuntar CV o Carta de presentación
                      <input
                        type="file"
                        accept=".pdf, .doc, .docx"
                        hidden
                        max-file-size="2048"
                        id="documento" 
                        name="documento" 
                      />
                    </Button>
                  </div>
                  <button style={{minWidth: '100px', backgroundColor: '#913616', color: 'white', fontWeight: 'bold'}} className={classes.customInputs} type="submit">Enviar</button>
                  {formularioEnviado && <p className="exito">Formulario enviado con exito!</p>}
                </form>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Grid item xs={12} md={12} sm={12}>
        <img src={loadImage('workWU1.jpeg')} alt="workwithus" style={{width: '100%'}}/>  
      </Grid>
    </Box>
  );
}
